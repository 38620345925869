import {
  BasicButton,
  BasicButtonProps,
} from '@core/components/Buttons/BasicButton/BasicButton.component';
import {
  BasicButtonAsLink,
  BasicButtonAsLinkProps,
} from '@core/components/Buttons/BasicButtonAsLink.component';
import { CrossIcon } from '@core/components/Icons/CrossIcon.component';
import { IconBaseProps } from '@core/components/Icons/types/IconBaseProps';
import { FunctionComponentWithChildren } from '@core/types/functionComponent.types';
import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';

export interface BannerProps {
  isClosable?: boolean;
  onClose?: () => void;
  noRoundedCorners?: boolean;
  noShadow?: boolean;
  type?: 'success' | 'error' | 'info' | 'warning';
  Icon?: FunctionComponent<IconBaseProps> | string;
  buttonProps?: Pick<BasicButtonProps, 'label' | 'variant' | 'size'> &
    (
      | {
          onClick: BasicButtonProps['onClick'];
          href?: never;
          openInNewTab?: never;
        }
      | {
          onClick?: never;
          href: BasicButtonAsLinkProps['href'];
          openInNewTab?: BasicButtonAsLinkProps['openInNewTab'];
        }
    );

  noBold?: boolean;
  isFullWidth?: boolean;
}

export const Banner: FunctionComponentWithChildren<BannerProps> = ({
  children,
  type = 'info',
  isClosable = false,
  onClose,
  noRoundedCorners = false,
  noShadow = false,
  Icon,
  buttonProps,
  isFullWidth,
  noBold,
}) => {
  const [isBannerOpen, setIsBannerOpen] = useState<boolean>(true);

  if (isClosable && !isBannerOpen) {
    return null;
  }

  return (
    <div
      className={classNames('relative h-fit border p-3 text-sm bg-white', {
        'border-radical-red text-radical-red': type === 'error',
        'border-dark-pastel-green text-dark-pastel-green': type === 'success',
        'border-klein-blue text-klein-blue': type === 'info',
        'border-orange text-orange': type === 'warning',
        'rounded-3xl': !noRoundedCorners,
        'shadow-md': !noShadow,
        'w-full': isFullWidth,
        'font-semibold': !noBold,
      })}
    >
      <div
        className={classNames(
          'flex w-full flex-col items-center gap-4 sm:flex-row sm:justify-between',
          {
            'pr-8': isClosable,
            'pr-0 pt-4 sm:pr-8 sm:pt-0': !!buttonProps && isClosable,
          }
        )}
      >
        <div className="flex items-center gap-4">
          {Icon ? (
            <div>
              {typeof Icon === 'string' ? (
                <div className="text-2xl">{Icon}</div>
              ) : (
                <Icon className="h-7 w-7 text-inherit" />
              )}
            </div>
          ) : null}
          <div className="text-center sm:text-left">{children}</div>
        </div>
        {buttonProps ? (
          buttonProps.href ? (
            <BasicButtonAsLink {...buttonProps} />
          ) : (
            <BasicButton {...buttonProps} />
          )
        ) : null}
      </div>

      {isClosable && (
        <div
          className={classNames('absolute', {
            'right-2 top-2 sm:right-4 sm:top-1/2 sm:-translate-y-1/2':
              !!buttonProps,
            'right-4 top-1/2 -translate-y-1/2': !buttonProps,
          })}
          onClick={() => {
            setIsBannerOpen(false);
            onClose && onClose();
          }}
        >
          <CrossIcon className="h-4 w-4 cursor-pointer text-inherit" />
        </div>
      )}
    </div>
  );
};
