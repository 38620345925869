import { useApolloQuery } from '@core/hooks/useApolloQuery.hook';
import { graphql } from 'graphql/generated';
import { useMyUserContext } from 'modules/MyUser/context/MyUserContext';
import { FrontendEventName } from 'modules/Pusher/frontendEvent';
import { usePusherUserEvent } from 'modules/Pusher/usePusherUserEvent';
import { useCallback, useState } from 'react';

const useUnreadChatMessagesCount_unreadMessagesCount = graphql(`
  query useUnreadChatMessagesCount_unreadMessagesCount {
    unreadMessagesCount
  }
`);

export const useUnreadChatMessagesCount = () => {
  const { myMarketplaceUser } = useMyUserContext();
  const [unreadChatMessagesCount, setUnreadChatMessagesCount] = useState<
    number | undefined
  >(undefined);

  useApolloQuery(useUnreadChatMessagesCount_unreadMessagesCount, {
    onCompleted: (data) => {
      setUnreadChatMessagesCount(data.unreadMessagesCount);
    },
    skip: !myMarketplaceUser,
  });

  const updateUnreadChatMessagesCount = useCallback(
    (data: { unreadMessagesCount: number }) => {
      setUnreadChatMessagesCount(data.unreadMessagesCount);
    },
    []
  );

  usePusherUserEvent(
    FrontendEventName.unreadMessagesCountUpdated,
    updateUnreadChatMessagesCount
  );

  return unreadChatMessagesCount;
};
